import React from 'react';
import styled from 'styled-components';
import { ThemeProvider, ScrollToTop } from '@transpiled/ui';
import defaultTheme from '../styles/theme';

import Banner from './Banner';
import About from './About';
import Menu from './Menu';
import Events from './Events';
import Contact from './Contact';
import Footer from './Footer';
import ContactBar from './ContactBar';

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 3000px;
  margin: 0 auto;
`;

const Home = () => (
  <ThemeProvider externalTheme={defaultTheme}>
    <ScrollToTop />
    <HomeWrapper>
      <ContactBar />
      <Banner />
      <About />
      <Menu />
      <Events />
      <Contact />
      <Footer />
    </HomeWrapper>
  </ThemeProvider>
);

export default Home;
