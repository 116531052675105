import React, { useState } from 'react';
import styled from 'styled-components';
import EventCard from './EventCard';
import { images } from '../../config';

const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
`;

const ImageWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

const LeftImage = styled.img`
  width: 300px;
  height: 400px;
  object-fit: cover;
  transform: perspective(800px) rotate(-10deg) rotateX(20deg); /* Rotates left and tilts back */
  transform-origin: center; /* Ensures the transformation happens from the center */
  position: relative;
  left: 45px;
  z-index: -1; /* Adjusted for visibility */
  bottom: 20px;
`;

const RightImage = styled.img`
  width: 300px;
  height: 400px;
  object-fit: cover;
  transform: perspective(800px) rotate(10deg) rotateX(15deg); /* Rotates right and tilts back */
  transform-origin: center; /* Ensures the transformation happens from the center */
  position: relative;
  right: 45px;
  top: 20px;
  z-index: -1; /* Adjusted for visibility */
`;

const events = [
  {
    id: 1,
    title: 'Dick Jagow',
    subtitle: 'West Hare Scramble GP',
    description:
      "Join us for breakfast and lunch! We'll be serving biscuits and gravy, breakfast sandwiches and coffee, followed by gourmet sausages and hotdogs for lunch in the afternoon. All fresh ingredients and made to order.",
    location: 'Washougal MX Park',
    date: 'SEPT 28-29 2024',
    image1: images.washougal1,
    image2: images.washougal2,
    learnMore:
      'https://www.omraoffroad.com/events/calendar/2024/4/28/xc-series-round-6-dick-jagow-hs',
  },
  {
    id: 2,
    title: 'Pro Caliber',
    subtitle: 'Open House - Sled Swap',
    description:
      "Join us for breakfast and lunch! We'll be serving biscuits and gravy, breakfast sandwiches and coffee, followed by gourmet sausages and hotdogs for lunch in the afternoon. All fresh ingredients and made to order.",
    location: 'Pro Caliber Bend',
    date: 'Oct 26th 9-5PM',
    image1: images.procaliber1,
    image2: images.procaliber2,
    learnMore:
      'https://www.instagram.com/p/DAUWGpOyYFd/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
  },
];

const EventDisplay = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < events.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const currentEvent = events[currentIndex];
  const totalEvents = events.length;

  return (
    <EventsContainer>
      <SliderWrapper>
        <ImageWrapper>
          <LeftImage
            src={currentEvent.image1}
            alt={`${currentEvent.title} Image 1`}
          />
        </ImageWrapper>
        <EventCard
          event={currentEvent}
          handleNext={handleNext}
          handlePrev={handlePrev}
          isNextDisabled={currentIndex === events.length - 1}
          isPrevDisabled={currentIndex === 0}
          totalEvents={totalEvents}
        />
        <ImageWrapper>
          <RightImage
            src={currentEvent.image2}
            alt={`${currentEvent.title} Image 2`}
          />
        </ImageWrapper>
      </SliderWrapper>
    </EventsContainer>
  );
};

export default EventDisplay;
